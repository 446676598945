module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FSVDR — Front end developer","short_name":"FSVDR","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8d8846cd42950f279b46c408d557d670"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"showCaptions":true,"wrapperStyle":"\n                margin-block-start: 4rem;\n                margin-block-end: 4rem;\n              "},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
